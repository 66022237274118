import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"messages-list"}},[_c(VRow,{staticClass:"mb-5"},_vm._l((_vm.ordersTotalLocal),function(total){return _c(VCol,{key:total.total,attrs:{"cols":"12","sm":"6","md":"3"}},[_c(VCard,[_c(VCardText,{staticClass:"d-flex align-center justify-space-between pa-4"},[_c('div',[_c('h2',{staticClass:"font-weight-semibold mb-1"},[_vm._v(" "+_vm._s(total.total)+" ")]),_c('span',[_vm._v(_vm._s(total.title))])])])],1)],1)}),1),_c(VCard,[_c(VCardTitle,[_vm._v(" Search & Filter ")]),_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VSpacer),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('div',{staticClass:"mb-4 mr-4 d-flex align-center"},[_c(VMenu,{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Start Date","placeholder":"yyyy-mm-dd","hide-details":"auto","outlined":"","readonly":"","dense":""},model:{value:(_vm.first_date),callback:function ($$v) {_vm.first_date=$$v},expression:"first_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isStartDateMenuOpen),callback:function ($$v) {_vm.isStartDateMenuOpen=$$v},expression:"isStartDateMenuOpen"}},[_c(VDatePicker,{attrs:{"no-title":""},on:{"input":function($event){_vm.isStartDateMenuOpen = false}},model:{value:(_vm.first_date),callback:function ($$v) {_vm.first_date=$$v},expression:"first_date"}})],1)],1),_c('div',{staticClass:"mb-4 mr-4 d-flex align-center justify-end"},[_c(VMenu,{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","eager":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"End Date","placeholder":"yyyy-mm-dd","hide-details":"auto","outlined":"","readonly":"","dense":""},model:{value:(_vm.last_date),callback:function ($$v) {_vm.last_date=$$v},expression:"last_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isEndDateMenuOpen),callback:function ($$v) {_vm.isEndDateMenuOpen=$$v},expression:"isEndDateMenuOpen"}},[_c(VDatePicker,{attrs:{"no-title":""},on:{"input":function($event){_vm.isEndDateMenuOpen = false}},model:{value:(_vm.last_date),callback:function ($$v) {_vm.last_date=$$v},expression:"last_date"}})],1)],1)])],1),_c(VDataTable,{attrs:{"id":"table","headers":_vm.tableColumns,"items":_vm.ordersListTable,"options":_vm.options,"server-items-length":_vm.totalOrdersListTable,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event},"refetch-data":function($event){return _vm.fetchOrders()}},scopedSlots:_vm._u([{key:"item.order_number",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name: 'apps-order-canceled-view', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.order_number)+" ")])],1)])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_vm._v(" "+_vm._s(_vm.moment(item.created_at))+" ")])])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" "+_vm._s(_vm.moment(item.updated_at))+" ")])])]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" "+_vm._s(item.time && item.time != 0 ? item.time : item.date)+" ")])])]}},{key:"item.payment_method",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" "+_vm._s(item.payment_method == 1 ? 'Cash' : item.payment_method == 2 ? 'Balance' : item.payment_method == 3 ? 'Payment' : '___')+" ")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{attrs:{"to":{ name: 'apps-order-canceled-view', params: { id: item.id } }}},[_c(VListItemTitle,[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")]),_c('span',[_vm._v("View")])],1)],1),_c(VListItem,{attrs:{"link":""},on:{"click":function($event){return _vm.copyRow(item)}}},[_c(VListItemTitle,[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiContentCopy)+" ")]),_c('span',[_vm._v("Copy Data")])],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }